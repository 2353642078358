import { getDictionary } from "../utilities/utilities";
import { CodLiquidation } from "./CodLiquidation";
import { Shipment } from "./Shipment";
import { Validate } from "./Validate";

const dictionary = getDictionary();

export interface CodLiquidationPreview extends CodLiquidation {
  customerName: string;
  customerLogoDarkUrl: string;
  customerLogoLightUrl: string;
  shipments: Shipment[];
  totalAmount: string;
}

export interface CreateCodLiquidationPreview {
  codCollections: string[];
  customerId: string;
  expectedAmount: string;
}

export const convertCodLiquidationPreviewToCreate = (
  liquidation: CodLiquidationPreview
): CreateCodLiquidationPreview => {
  return {
    codCollections: liquidation.codCollections,
    customerId: liquidation.customer.id,
    expectedAmount: liquidation.expectedAmount,
  };
};

export const validate = (liquidation: CodLiquidationPreview): Validate => {
  const validate: Validate = { isValid: true, errors: {} };
  if (!liquidation.expectedAmount) {
    validate.errors.expectedAmount = dictionary.messages.mandatory_field;
    validate.isValid = false;
  }

  return validate;
};
