import React from "react";
import { transformStringToColor } from "../../utilities/utilities";
import { isColorDark } from "../../utilities/utilities";

export interface BadgeProps {
  bgColor?: string;
  text: string;
}

const Badge: React.FC<BadgeProps> = ({ bgColor, text }) => {
  const backgroundColor = bgColor ?? transformStringToColor(text);
  return (
    <span
      style={{
        backgroundColor: backgroundColor,
        color: isColorDark(backgroundColor) ? "#fff" : "#000",
      }}
      className="gen-badge"
    >
      {text}
    </span>
  );
};

export default Badge;
