import React, { useCallback } from "react";
import { useAppContext } from "../../AppProvider";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { useIndexCodCollectionsContext } from "./providers/Index";
import { useCodLiquidationPreviewContext } from "./providers/LiquidationPreview.tsx";
import ButtonConfirm from "../../Components/Buttons/Confirm";
import { CodLiquidationPreview } from "../../Models/CodLiquidationPreview.ts";
import InputBoxIcon from "../../Components/Inputs/InputIcon/InputIcon";
import { faEuro, faEye } from "../../utilities/fontawesomeIcons";
import { List } from "../../Components/common/List/List.jsx";
import { Shipment } from "../../Models/Shipment.ts";
import moment from "moment";
import { useTypes } from "../../utilities/types.js";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFieldError } from "../../utilities/utilities.ts";

const AnyList = List as any;

const LiquidationPreview: React.FC = () => {
  const { dictionary } = useAppContext();
  const { modalConfig } = useIndexCodCollectionsContext();
  const { data, error, callback, loader, edit, onSubmit } =
    useCodLiquidationPreviewContext();

  const handleChange = (
    name: string,
    value: string | number | object | null,
    key: string
  ) => {
    edit(
      {
        ...data,
        [name]: value,
      } as CodLiquidationPreview,
      key as keyof CodLiquidationPreview
    );
  };

  const renderItem = useCallback((item: Shipment, key: number) => {
    return <ListItem key={key} item={item} />;
  }, []);

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <h3 className="text-center">{modalConfig.title}</h3>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <label className="label-2">{dictionary.registries.customer}:</label>
          <label className="label-3">
            <img
              style={{ maxHeight: "30px", maxWidth: "50px" }}
              src={data.customerLogoDarkUrl}
            />
            {data.customerName}
          </label>
        </div>
        <div className="col">
          <label className="label-2">
            {dictionary.dimensions.total_amount}:
          </label>
          <label className="label-3">{data.totalAmount}</label>
        </div>
        <div className="col">
          <label className="label-2">
            {dictionary.dimensions.expected_amount}
          </label>
          <InputBoxIcon
            name={"expectedAmount"}
            type="positive-number"
            onChange={({ target }) => {
              handleChange(target.name, target.value, "expectedAmount");
            }}
            value={data.expectedAmount}
            icon={faEuro}
            error={getFieldError(error, "expectedAmount")}
          />
        </div>
      </div>
      <AnyList
        title={<h3 className="m-0">{dictionary.shipments.shipments}</h3>}
        data={data?.shipments}
        renderItem={renderItem}
        pagination={{ enabled: false }}
        actionBar={{ enabled: false }}
      />
      <div className="d-flex">
        <ButtonUtility
          className="w-50 me-2"
          onClick={() => {
            if (callback) {
              callback();
            }
          }}
        >
          {dictionary.actions.cancel}
        </ButtonUtility>
        <ButtonConfirm
          className="w-50"
          onClick={onSubmit}
          disabled={loader}
          loading={loader}
        >
          {dictionary.actions.confirm}
        </ButtonConfirm>
      </div>
    </React.Fragment>
  );
};

export default LiquidationPreview;

const ListItem: React.FC<{ item: Shipment }> = React.memo(({ item }) => {
  const { dictionary } = useAppContext();
  const { currencies } = useTypes();

  return (
    <div className="list-item">
      <div
        className="d-grid grid-column align-items-start"
        style={{ gridTemplateColumns: "0.5fr 0.5fr 0.5fr 1fr 0.5fr auto" }}
      >
        <div>
          <label className="label-2">
            {dictionary.references.internal_reference}:
          </label>
          <label className="label-3">{item.internalReference}</label>
        </div>
        <div>
          <label className="label-2">
            {dictionary.references.carrier_reference}:
          </label>
          <label className="label-3">{item.carrierReference}</label>
        </div>
        <div>
          <label className="label-2">{dictionary.dates.delivery_date}:</label>
          <label className="label-3">
            {moment(item.deliveryDate).format("DD/MM/YYYY")}
          </label>
        </div>
        <div>
          <label className="label-2">{dictionary.registries.receiver}:</label>
          <label className="label-3">
            {item.deliveryName} - {item.deliveryAddress}
          </label>
        </div>
        <div>
          <label className="label-2">
            {dictionary.dimensions.cash_on_delivery_value}:
          </label>
          <label className="label-3">
            {item.cashOnDeliveryValue}{" "}
            {
              currencies.find(
                (c) => c.value === item.cashOnDeliveryValueCurrency
              )?.label
            }
          </label>
        </div>
        <div>
          <Link
            className="button-1 button-1-light button-1-icon me-1"
            to={`/shipments/details/${item.internalReference}`}
            title={dictionary.actions.details}
            target="_blank"
          >
            <FontAwesomeIcon icon={faEye} />
          </Link>
        </div>
      </div>
      <hr className="my-1"></hr>
    </div>
  );
});
