import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { createModalConfig, ModalConfig } from "../../../Models/ModalConfig";

interface IndexCodCollectionsProviderProps {
  children: ReactNode;
  callback?: () => void;
  parentId: string;
}

interface IndexCodCollectionsContextValue {
  modalConfig: ModalConfig;
  setModalConfig: Dispatch<SetStateAction<ModalConfig>>;
  callback?: () => void;
  parentId: string;
}

const IndexCodCollectionsContext = createContext<
  IndexCodCollectionsContextValue | undefined
>(undefined);

const IndexCodCollectionsProvider: React.FC<
  IndexCodCollectionsProviderProps
> = ({ children, callback, parentId }) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig>(
    createModalConfig()
  );

  return (
    <IndexCodCollectionsContext.Provider
      value={{
        modalConfig,
        setModalConfig,
        callback,
        parentId,
      }}
    >
      {children}
    </IndexCodCollectionsContext.Provider>
  );
};

const useIndexCodCollectionsContext = (): IndexCodCollectionsContextValue => {
  const context = useContext(IndexCodCollectionsContext);
  if (!context) {
    throw new Error(
      "useIndexCodCollectionsContext must be used within an IndexCodCollectionsProvider"
    );
  }
  return context;
};

export { IndexCodCollectionsProvider, useIndexCodCollectionsContext };
