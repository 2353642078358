import { AxiosError } from "axios";
import { toast, ToastPosition } from "react-toastify";
import it from "./dictionaries/it.json";
import en from "./dictionaries/en.json";
import fr from "./dictionaries/fr.json";

const dictionaries: Record<string, any> = { it, en, fr };
/**
 * Returns the dictionary for the user's selected language.
 *
 * This function retrieves the preferred language from `localStorage` or
 * `navigator.language`. If the language is not supported, English is used as a fallback.
 *
 * @returns {object} The dictionary corresponding to the user's language.
 */
export const getDictionary = (): Record<string, any> => {
  const userLanguage =
    localStorage.getItem("language") || navigator.language || "en";
  const normalizedLanguage = userLanguage.split("-")[0];
  return dictionaries[normalizedLanguage] || dictionaries["en"];
};

/**
 * Retrieves the error message associated with a specific field in an HTTP error response.
 *
 * This function checks if the provided error is an instance of `AxiosError` and,
 * if so, extracts the error message for the specified field from the API response.
 *
 * @param {unknown} error - The error received from the HTTP request.
 * @param {string} field - The name of the field for which to retrieve the error message.
 * @returns {string | undefined} The error message for the specified field, or `undefined` if not available.
 */
export const getFieldError = (
  error: unknown,
  field: string
): string | undefined => {
  if (error && typeof error === "object") {
    //BE error
    if ("isAxiosError" in error) {
      return (error as AxiosError<{ [key: string]: string }>).response?.data?.[
        field
      ];
    }
    //FE error
    return (error as { response: { data: Record<string, string> } }).response
      .data[field];
  }

  return undefined;
};

/**
 * Displays an error toast notification based on the provided error object.
 *
 * This function processes different types of errors and extracts meaningful messages
 * to display as toast notifications. It handles network errors, HTTP status codes (400, 404, 500),
 * and any unexpected errors. If a dictionary object is provided, it uses localized error messages.
 *
 * @param err - The error object, which can be of any type (preferably an object with `code` and `response` properties).
 * @param dictionary - An optional dictionary object containing localized messages. Defaults to the current language from `dictionaries`.
 *
 * @example
 * ```ts
 * try {
 *   await fetchData();
 * } catch (error) {
 *   callErrorToast(error);
 * }
 * ```
 */
export const callErrorToast = (
  err: unknown,
  dictionary: any = dictionaries[
    localStorage.getItem("language") || navigator.language || "en"
  ]
) => {
  let message = "";

  if (typeof err === "object" && err !== null) {
    const errorObj = err as {
      code?: string;
      response?: { status?: number; data?: any; message?: string };
    };

    if (errorObj.code === "ERR_NETWORK") {
      message = dictionary.messages.network_error;
    } else {
      switch (errorObj.response?.status) {
        case 400:
          message =
            "400 - Bad Request - " +
            (errorObj.response.data
              ? JSON.stringify(errorObj.response.data)
              : errorObj.response.message || "");
          break;
        case 404:
          message = "404 - Not Found";
          break;
        case 500:
          message = "500 - Internal Server Error";
          break;
        default:
          message = `Internal Web Error: ${JSON.stringify(err)}`;
      }
    }
  } else {
    message = `Unexpected Error: ${JSON.stringify(err)}`;
  }

  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};

/**
 * Displays an error toast notification with the provided message.
 *
 * This function displays an error toast notification on the screen with the given message.
 * The toast will appear at a specific position on the screen, which can be customized.
 * By default, the toast will be displayed at the top-center of the screen.
 *
 * Note: Despite the function name suggesting it's for success messages, this function
 * uses `toast.error` internally, meaning it displays an error toast notification.
 * Consider renaming the function if the intention is to show success notifications.
 *
 * @param message - The error message to display in the toast notification.
 * @param position - The position where the toast notification should appear.
 *   Defaults to `toast.POSITION.TOP_CENTER` if not provided.
 *
 * @example
 * ```ts
 * callSuccessToast("Something went wrong while processing your request.");
 * ```
 */
export const callSuccessToast = (
  message: string,
  position: ToastPosition = toast.POSITION.TOP_CENTER
) => {
  toast.success(message, {
    position,
  });
};

/**
 * Determines if a given color (in hex format) is dark or light.
 * Uses the luminance formula to calculate brightness and returns true if the color is considered dark.
 *
 * @param color - A hex color code string (e.g., "#RRGGBB").
 * @returns `true` if the color is dark, `false` if the color is light.
 *
 * @example
 * isColorDark("#000000"); // true
 * isColorDark("#FFFFFF"); // false
 */
export const isColorDark = (color: string): boolean => {
  if (!/^#[0-9A-Fa-f]{6}$/.test(color)) {
    throw new Error("Invalid hex color format. Expected format: #RRGGBB.");
  }

  const r = parseInt(color.slice(1, 3), 16); // Replaced substr with slice
  const g = parseInt(color.slice(3, 5), 16); // Replaced substr with slice
  const b = parseInt(color.slice(5, 7), 16); // Replaced substr with slice

  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  return brightness < 128;
};

/**
 * Generates a color code (hex format) based on a string input.
 * This function creates a unique color hash by iterating through the string's characters
 * and applies bit manipulation to convert the string into a hex color code.
 *
 * @param str - The input string used to generate the color.
 * @returns A string representing the generated color in hex format (e.g., "#RRGGBB").
 *
 * @example
 * stringToColor("hello"); // Example output: "#7b7b7b"
 */
export const transformStringToColor = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }

  return color;
};
/**
 * Removes properties with falsy values from an object.
 *
 * This function returns a new copy of the object with all properties that have
 * falsy values (`null`, `undefined`, `false`, `0`, `""`, etc.) removed.
 *
 * @template T - A generic type representing the shape of the input object.
 * @param {T} obj - The input object of any type extending `Record<string, any>`.
 * @returns {T} A new object with falsy properties removed.
 *
 * @example
 * const person = { name: "John", age: 30, city: "" };
 * const cleanedPerson = clearObject(person);
 * console.log(cleanedPerson); // { name: "John", age: 30 }
 */
export const cleanObject = <T extends Record<string, any>>(obj: T): T => {
  const newObj = { ...obj };
  for (const prop in newObj) {
    if (!newObj[prop]) {
      delete newObj[prop];
    }
  }
  return newObj;
};
