import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import {
  convertStockReleaseShipmentToCreate,
  ErrorResponse,
  StockReleaseShipment,
  validate,
} from "../../../Models/StockReleaseShipment";
import { isEqual } from "lodash";
import { StockReleasesService } from "../../../services/shipment/stockReleases";
import { StockReleasesService as StockReleaseMethodsService } from "../../../services/registry/stockReleases";
import { callErrorToast } from "../../../utilities/utilities";
import { ShipmentEvent } from "../../../Models/ShipmentEvent";
import { createModalConfig, ModalConfig } from "../../../Models/ModalConfig";

// Instantiate service classes for API calls
const stockReleasesService = new StockReleasesService();
const stockReleaseMethodsService = new StockReleaseMethodsService();

/**
 * Props for the HandlerStockReleaseShipmentProvider component.
 */
interface HandlerStockReleaseShipmentProviderProps {
  id?: string; // Optional ID of the warehouse article (if editing)
  children: ReactNode; // React children components that will be wrapped by the provider
  callback?: () => void; // Optional function to execute after a successful action
  action: string;
  event: ShipmentEvent & {
    internalReference: string;
    carrierReference: string;
  };
}

/**
 * Context value that will be shared with consumers.
 */
interface HandlerStockReleaseShipmentContextValue {
  modalConfig: ModalConfig; // Configuration settings for modals
  setModalConfig: Dispatch<SetStateAction<ModalConfig>>; // Function to update modal configuration
  data: Partial<StockReleaseShipment>; // The stockRelease data being managed
  error: ErrorResponse | null; // Error response if any request fails
  loader: boolean; // Indicates whether an operation is in progress
  action: string; // The current action (e.g., "create", "edit")
  event?: ShipmentEvent & {
    internalReference: string;
    carrierReference: string;
  };
  edit: (
    stockRelease: StockReleaseShipment,
    property: keyof StockReleaseShipment | null,
    save: boolean
  ) => void; // Function to edit/update the warehouse article
  callback?: () => void; // Optional function to execute after a successful action
  onSubmit: (id?: string) => void; // Function to submit (create or update) the warehouse article
}

// Create the context with an initial value of undefined
const HandlerStockReleaseShipmentContext = createContext<
  HandlerStockReleaseShipmentContextValue | undefined
>(undefined);

/**
 * Provider component for managing stockRelease-related data and operations.
 * Handles retrieval, creation, and modification of stockReleases.
 */
const HandlerStockReleaseShipmentProvider: React.FC<
  HandlerStockReleaseShipmentProviderProps
> = ({ id, children, callback, event, action }) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig>(
    createModalConfig()
  ); // State for managing modal configuration

  // State management
  const [data, setData] = useState<Partial<StockReleaseShipment>>({
    shipmentInternalReference: event?.internalReference,
  });
  const [loader, setLoader] = useState<boolean>(true);
  const [error, setError] = useState<ErrorResponse | null>(null);

  const prevError = useRef<ErrorResponse>(null);

  /**
   * Fetches stockRelease details by ID.
   * @param id - The stockRelease ID.
   */
  const getStockRelease = (id: string): void => {
    setLoader(true);
    stockReleasesService
      .get(id)
      .then((res) => {
        getStockReleaseMethod(res.data);
      })
      .catch((err) => {
        setLoader(false);
        setError(err);
      });
  };

  /**
   * Fetches stockReleaseMethod by stockRelaseCode.
   * @param stockRelease - The stockRelease.
   */
  const getStockReleaseMethod = (stockRelease: StockReleaseShipment): void => {
    stockReleaseMethodsService
      .get(stockRelease.stockReleaseCode)
      .then((res) => {
        stockRelease.stockRelease = res.data;
        setLoader(false);
        setData(stockRelease);
      })
      .catch((err) => {
        setLoader(false);
        setError(err);
      });
  };

  /**
   * Handles submission of the warehouse article.
   * If an ID exists, the article is updated; otherwise, a new one is created.
   * @param id - The ID of the warehouse article (if updating).
   */
  const onSubmit = (id?: string): void => {
    if (id) {
      edit(data as StockReleaseShipment, null);
      return;
    }
    create();
  };

  /**
   * Creates a new stockRelease and navigates to the edit page.
   */
  const create = (): void => {
    setLoader(true);
    const errors = validate(data as StockReleaseShipment);
    if (errors) {
      setError({ response: { data: errors } });
      setLoader(false);
      return;
    }

    const stockReleaseToCreate = convertStockReleaseShipmentToCreate(
      data as StockReleaseShipment
    );

    stockReleasesService
      .create(stockReleaseToCreate)
      .then((res) => {
        if (callback) {
          callback();
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        callErrorToast(err);
      });
  };

  /**
   * Edits an existing stock release.
   * @param stockRelease - The stock release object being edited.
   * @param property - The specific property being edited (optional).
   */
  const edit = (
    stockRelease: StockReleaseShipment,
    property: keyof StockReleaseShipment | null
  ): void => {
    if (property) {
      removeError(property); // Remove any existing validation error for the edited property
    }
    setData(stockRelease); // Update local state without saving
  };

  /**
   * Removes an error related to a specific property.
   * @param property - The property for which the error should be removed.
   */
  const removeError = (property: keyof StockReleaseShipment) => {
    if (error && error.response) {
      const { data } = error.response;
      if (data && property in data) {
        const { [property]: _, ...newData } = data;
        setError({ response: { data: newData } });
        prevError.current = { response: { data: newData } };
      }
    }
  };

  useEffect(() => {
    if (data.stockRelease?.code) {
      const newData = { ...data };
      if (!data.stockRelease.addressRequired) delete newData.newDelivery;
      if (!data.stockRelease.noteRequired) delete newData.note;
      if (!data.stockRelease.deliveryDateRequired)
        delete newData.newDeliveryDate;
      setData(newData);
    }
  }, [data.stockRelease]);

  /**
   * Effect to handle error notifications.
   */
  useEffect(() => {
    if (
      error &&
      typeof error === "object" &&
      !isEqual(prevError.current, error)
    ) {
      prevError.current = error;
      callErrorToast(error);
    }
  }, [error]);

  useEffect(() => {
    if (id) {
      getStockRelease(id);
      return;
    }
    setLoader(false);
  }, []);

  return (
    <HandlerStockReleaseShipmentContext.Provider
      value={{
        modalConfig,
        setModalConfig,
        data,
        loader,
        error,
        edit,
        action,
        event,
        callback,
        onSubmit,
      }}
    >
      {children}
    </HandlerStockReleaseShipmentContext.Provider>
  );
};

/**
 * Custom hook to access the HandlerStockReleaseShipmentContext.
 * Ensures that the context is used only within a HandlerStockReleaseShipmentProvider.
 */
const useHandlerContext = (): HandlerStockReleaseShipmentContextValue => {
  const context = useContext(HandlerStockReleaseShipmentContext);
  if (!context) {
    throw new Error(
      "useHandlerStockReleaseShipmentContext must be used within a HandlerStockReleaseShipmentProvider"
    );
  }
  return context;
};

// Export the provider and custom hook for use in other components
export { HandlerStockReleaseShipmentProvider, useHandlerContext };
