import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import { callErrorToast } from "../../../utilities/index.js";
import { CodCollectionsService } from "../../../services/shipment/codCollections.ts";
import {
  convertCodLiquidationToCreate,
  CodLiquidation,
  validate,
} from "../../../Models/CodLiquidation.ts";
import { useIndexCodCollectionsContext } from "./Index.tsx";
import { useAppContext } from "../../../AppProvider.jsx";
import { isEmpty } from "lodash";
import { cleanObject } from "../../../utilities/utilities.ts";

const codCollectionsService = new CodCollectionsService();

interface CodLiquidationProviderProps {
  children: ReactNode;
  liquidation: CodLiquidation;
  callback?: () => void;
}

interface CodLiquidationContextValue {
  data: Partial<CodLiquidation>;
  error: ErrorResponse | null; // Error response if any request fails
  loader: boolean;
  edit: (
    liquidation: CodLiquidation,
    property: keyof CodLiquidation | null,
    save?: boolean
  ) => void;
  callback?: () => void;
  onSubmitPreview: () => void;
}

interface ErrorResponse {
  response?: {
    data: Partial<CodLiquidation>;
  };
}

const CodLiquidationContext = createContext<
  CodLiquidationContextValue | undefined
>(undefined);

const CodLiquidationProvider: React.FC<CodLiquidationProviderProps> = ({
  children,
  liquidation,
  callback,
}) => {
  const { dictionary } = useAppContext();

  const [data, setData] = useState<Partial<CodLiquidation>>(liquidation);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const prevError = useRef<ErrorResponse>(null);

  const { setModalConfig } = useIndexCodCollectionsContext();

  const edit = (
    liquidation: CodLiquidation,
    property: keyof CodLiquidation | null
  ): void => {
    liquidation = cleanObject(liquidation);
    if (property) {
      removeError(property);
    }
    setData(liquidation);
  };

  const onSubmitPreview = (): void => {
    const validation = validate(data as CodLiquidation);
    if (!validation.isValid) {
      setError({ response: { data: validation.errors } });
      return;
    }
    setLoader(true);

    const liquidationUpdated = convertCodLiquidationToCreate(
      data as CodLiquidation
    );

    codCollectionsService
      .liquidationPreview(liquidationUpdated)
      .then((res) => {
        setLoader(false);
        setModalConfig({
          size: "lg",
          isOpen: true,
          title: dictionary.cod_returns.liquidate_labels,
          action: "codLiquidationPreview",
          data: { ...data, ...res.data },
        });
      })
      .catch((err) => {
        setLoader(false);
        setError(err);
      });
  };

  /**
   * Removes an error related to a specific property.
   * @param property - The property for which the error should be removed.
   */
  const removeError = (property: keyof CodLiquidation) => {
    if (error && error.response) {
      const { data } = error.response;
      if (data && property in data) {
        const { [property]: _, ...newData } = data;
        if (isEmpty(newData)) {
          setError(null);
          return;
        }
        setError({ response: { data: newData } });
        prevError.current = { response: { data: newData } };
      }
    }
  };

  useEffect(() => {
    if (error) {
      callErrorToast(error);
    }
  }, [error]);

  return (
    <CodLiquidationContext.Provider
      value={{
        data,
        error,
        loader,
        edit,
        callback,
        onSubmitPreview,
      }}
    >
      {children}
    </CodLiquidationContext.Provider>
  );
};

const useCodLiquidationContext = (): CodLiquidationContextValue => {
  const context = useContext(CodLiquidationContext);
  if (!context) {
    throw new Error(
      "useCodLiquidationContext must be used within a CodLiquidationProvider"
    );
  }
  return context;
};

export { CodLiquidationProvider, useCodLiquidationContext };
