import { API_BASE_URL } from "../../constants/index";
import HttpInterface from "../../helpers/HttpInterface";
import { UpdateCollectionDate } from "../../Models/CodCollection";
import { CreateLiquidation, CodLiquidation } from "../../Models/CodLiquidation";
import {
  CodLiquidationPreview,
  CreateCodLiquidationPreview,
} from "../../Models/CodLiquidationPreview";

export class CodCollectionsService {
  private httpProxy: HttpInterface;

  constructor() {
    this.httpProxy = new HttpInterface();
  }

  /**
   * Updates collection date of the cod-collection.
   *
   * @param codCollection
   * @returns A promise that resolves with the API response confirming the updating of the collection date.
   */
  updateCollectionDate(codCollection: UpdateCollectionDate): Promise<any> {
    const requestOptions = {
      method: "POST",
      body: codCollection.collectionDate,
    };

    return this.httpProxy.fetch(
      `${API_BASE_URL}/shipment-service/cod-collections/${codCollection.id}/update-collection-date`,
      requestOptions
    );
  }

  liquidationPreview(liquidation: CreateLiquidation): Promise<any> {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(liquidation),
    };

    return this.httpProxy.fetch(
      `${API_BASE_URL}/shipment-service/cod-collections/liquidation/preview`,
      requestOptions
    );
  }

  liquidationSubmit(liquidation: CreateCodLiquidationPreview): Promise<any> {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(liquidation),
    };

    return this.httpProxy.fetch(
      `${API_BASE_URL}/shipment-service/cod-collections/liquidation/submit`,
      requestOptions
    );
  }
}
