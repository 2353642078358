export interface CodCollection {
  id?: string;
  ownerId: string;
  note: string;
  externalReference: string;
  transactionReference: string;
  supplierId: string;
  supplierLogoDarkUrl: string;
  supplierLogoLightUrl: string;
  supplierType: string;
  customerId: string;
  customerLogoDarkUrl: string;
  customerLogoLightUrl: string;
  collectionDate: Date;
  collectedAmount: number;
}

export interface UpdateCollectionDate {
  id: string;
  collectionDate: string;
}

// Convert function to update codCollection
export const convertCodCollectionToUpdateCollectionDate = (
  codCollection: CodCollection
): UpdateCollectionDate => {
  return {
    id: codCollection.id as string,
    collectionDate:
      codCollection.collectionDate instanceof Date
        ? codCollection.collectionDate.toISOString()
        : codCollection.collectionDate,
  };
};
