import React, { useRef } from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { useAppContext } from "../../AppProvider";
import { useListProps } from "./utilities/list";
import { TooltipCopy } from "../../Components/common/Tooltips/TooltipCopy";
import ButtonUtility from "../../Components/Buttons/Utility";
import { faCalendarPlus, faEye } from "../../utilities/fontawesomeIcons";
import { useIndexCodCollectionsContext } from "./providers/Index";
import { Modal, ModalBody } from "reactstrap";
import { createModalConfig } from "../../Models/ModalConfig";
import { UpdateCollectionDateProvider } from "./providers/UpdateCollectionDate";
import UpdateCollectionDate from "./UpdateCollectionDate";
import Liquidation from "./Liquidation";
import { CodLiquidationProvider } from "./providers/Liquidation";
import { CodLiquidationPreviewProvider } from "./providers/LiquidationPreview";
import LiquidationPreview from "./LiquidationPreview";

const CODCollections = () => {
  const listRef = useRef(null);
  const listProps = useListProps();
  const { modalConfig, setModalConfig } = useIndexCodCollectionsContext();

  const renderItem = (item, key) => {
    return <ListItem item={item} index={key} />;
  };

  const closeModal = () => {
    setModalConfig(createModalConfig());
  };

  return (
    <React.Fragment>
      <ListProvider
        path={listProps.path}
        sorting={listProps.sorting}
        filters={listProps.filters}
        defaultDate={listProps.defaultDate}
      >
        <List
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          omnisearch={listProps.omnisearch}
          exports={listProps.exports}
          renderItem={renderItem}
        />
      </ListProvider>

      <Modal isOpen={modalConfig.isOpen} size={modalConfig.size}>
        <ModalBody className={modalConfig.className || ""}>
          {modalConfig.action === "codLiquidation" && (
            <CodLiquidationProvider
              liquidation={{ codCollections: [...modalConfig.data] }}
              callback={() => {
                listRef.current?.refresh();
                closeModal();
              }}
            >
              <Liquidation />
            </CodLiquidationProvider>
          )}
          {modalConfig.action === "codLiquidationPreview" && (
            <CodLiquidationPreviewProvider
              liquidationPreview={modalConfig.data}
              callback={() => {
                listRef.current?.refresh();
                closeModal();
              }}
            >
              <LiquidationPreview />
            </CodLiquidationPreviewProvider>
          )}
          {modalConfig.action === "updateCollectionDate" && (
            <UpdateCollectionDateProvider
              codCollection={modalConfig.data}
              callback={() => {
                listRef.current?.refresh();
                closeModal();
              }}
            >
              <UpdateCollectionDate />
            </UpdateCollectionDateProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CODCollections;

const ListItem = React.memo(({ item, index }) => {
  const { dictionary, accessTokenPayload } = useAppContext();
  const { setModalConfig } = useIndexCodCollectionsContext();

  return (
    <React.Fragment key={index}>
      <div
        className="list-item d-grid align-items-center px-2"
        style={{
          gridAutoFlow: "column",
          gridTemplateColumns: `1fr 2fr 0.75fr 0.75fr 1fr 1fr 0.5fr 1.5fr`,
          gap: "0.5rem",
        }}
      >
        <div>
          <label className="label-2">{dictionary.dates.creation_date}:</label>
          <label className="label-3">
            {moment(item.creationDate).format("DD/MM/YYYY")}
          </label>
        </div>
        <div>
          <label className="label-2">
            {dictionary.references.external_reference}:
          </label>
          <TooltipCopy
            target={"externalReference_" + index}
            textToCopy={item.externalReference}
          >
            <label
              className={`label-3 ${
                item.externalReference ? "cursor-pointer" : ""
              }`}
            >
              {item.externalReference || "-"}
            </label>
          </TooltipCopy>
        </div>
        <div className="text-center">
          <label className="label-2">{dictionary.registries.supplier}:</label>
          {item.supplierLogoUrl && (
            <img src={item.supplierLogoUrl} title={item.supplierName} />
          )}
        </div>
        <div className="text-center">
          <label className="label-2">{dictionary.registries.customer}:</label>
          {item.customerLogoUrl && (
            <img src={item.customerLogoUrl} title={item.customerName} />
          )}
        </div>
        <div>
          <label className="label-2">
            {dictionary.dimensions.expected_amount}:
          </label>
          <label className="label-3">
            {item.expectedAmount ? `${item.expectedAmount.toFixed(2)} €` : "-"}
          </label>
        </div>
        <div>
          <label className="label-2">
            {dictionary.dimensions.collected_amount}:
          </label>
          <label className="label-3">
            {item.collectedAmount
              ? `${item.collectedAmount.toFixed(2)} €`
              : "-"}
          </label>
        </div>

        <div>
          <label className="label-2">{dictionary.words.delta}:</label>
          <label
            className={`label-3 ${
              item.collectedAmount - item.expectedAmount >= 0
                ? "text-success"
                : "text-danger"
            }`}
          >
            {item.collectedAmount && item.expectedAmount
              ? `${(item.collectedAmount - item.expectedAmount).toFixed(2)} €`
              : "-"}
          </label>
        </div>
        <div>
          <label className="label-2">{dictionary.dates.collected_date}:</label>
          <label className="label-3">
            {item.collectionDate
              ? moment(item.collectionDate).format("DD/MM/YYYY")
              : "-"}
          </label>
        </div>
        <div>
          {item.customerId === accessTokenPayload.owner && (
            <ButtonUtility
              title={`${
                dictionary.actions.update
              } ${dictionary.dates.collected_date.toLowerCase()}`}
              onClick={() => {
                setModalConfig({
                  isOpen: true,
                  title: `${
                    dictionary.actions.update
                  } ${dictionary.dates.collected_date.toLowerCase()}`,
                  action: "updateCollectionDate",
                  data: item,
                });
              }}
              className={`button-1-icon me-1`}
            >
              <FontAwesomeIcon icon={faCalendarPlus} />
            </ButtonUtility>
          )}
          <Link
            target="_blank"
            className="button-1 button-1-light button-1-icon"
            to={`/cod-collections/details/${item.id}`}
            title={dictionary.actions.details}
          >
            <FontAwesomeIcon icon={faEye} />
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
});
