import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import { callErrorToast } from "../../../utilities/index.js";
import { CodCollectionsService } from "../../../services/shipment/codCollections.ts";
import {
  CodCollection,
  convertCodCollectionToUpdateCollectionDate,
} from "../../../Models/CodCollection.ts";

const codCollectionsService = new CodCollectionsService();

interface UpdateCollectionDateProviderProps {
  children: ReactNode;
  codCollection: CodCollection;
  callback?: () => void;
}

interface UpdateCollectionDateContextValue {
  data: CodCollection;
  loader: boolean;
  edit: (
    codCollection: CodCollection,
    property: keyof CodCollection | null,
    save?: boolean
  ) => void;
  callback?: () => void;
  onSubmit: () => void;
}

interface ErrorResponse {
  response?: {
    data: Partial<CodCollection>;
  };
}

const UpdateCollectionDateContext = createContext<
  UpdateCollectionDateContextValue | undefined
>(undefined);

const UpdateCollectionDateProvider: React.FC<
  UpdateCollectionDateProviderProps
> = ({ children, codCollection, callback }) => {
  const [data, setData] = useState<CodCollection>(codCollection);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const prevError = useRef<ErrorResponse>(null);

  const edit = (
    codCollection: CodCollection,
    property: keyof CodCollection | null
  ): void => {
    console.log(codCollection);
    if (property) {
      removeError(property);
    }
    setData(codCollection);
  };

  const onSubmit = (): void => {
    const codCollectionUpdated =
      convertCodCollectionToUpdateCollectionDate(data);
    codCollectionsService
      .updateCollectionDate(codCollectionUpdated)
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        setLoader(false);
        setError(err);
      });
  };

  /**
   * Removes an error related to a specific property.
   * @param property - The property for which the error should be removed.
   */
  const removeError = (property: keyof CodCollection) => {
    if (error && error.response) {
      const { data } = error.response;
      if (data && property in data) {
        const { [property]: _, ...newData } = data;
        setError({ response: { data: newData } });
        prevError.current = { response: { data: newData } };
      }
    }
  };

  useEffect(() => {
    if (error) {
      callErrorToast(error);
    }
  }, [error]);

  return (
    <UpdateCollectionDateContext.Provider
      value={{ data, loader, edit, callback, onSubmit }}
    >
      {children}
    </UpdateCollectionDateContext.Provider>
  );
};

const useUpdateCollectionDateContext = (): UpdateCollectionDateContextValue => {
  const context = useContext(UpdateCollectionDateContext);
  if (!context) {
    throw new Error(
      "useUpdateCollectionDateContext must be used within a UpdateCollectionDateProvider"
    );
  }
  return context;
};

export { UpdateCollectionDateProvider, useUpdateCollectionDateContext };
