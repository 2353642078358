import React from "react";
import { Company } from "../../../Models/Company";
import { useTypes } from "../../../utilities/types";
import { getBadge } from "../../Badges/utilities";

// Funzione che restituisce un componente React (FC)
export const getCompanyOptionStyle = (option: Company): React.FC => {
  const CompanyOption: React.FC = () => {
    const { companies } = useTypes();
    const companyType = companies.find((c) => c.value === option.type);
    return (
      <>
        <img
          src={option.logoDarkUrl}
          className="me-2"
          style={{ maxHeight: "30px", maxWidth: "50px" }}
          alt={option.name}
        />
        <label className="d-inline-block me-2">{option.name}</label>
        {option.externalReference && (
          <span className="me-2" style={{ fontSize: "10px" }}>
            ({option.externalReference})
          </span>
        )}
        {getBadge({ bgColor: companyType?.color, text: companyType?.label })}
      </>
    );
  };

  return CompanyOption;
};
