import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "../../utilities/fontawesomeIcons";
import { useAppContext } from "../../AppProvider";
import { Button } from "../../Models/Button";

const ButtonUtility: React.FC<Button> = ({
  children,
  className = "",
  onClick,
  disabled = false,
  loading = false,
  title = "",
}) => {
  const { dictionary } = useAppContext();

  return (
    <button
      className={`button-1 button-1-light ${className}`}
      onClick={onClick}
      disabled={disabled || loading}
      title={title}
    >
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <>{children || dictionary.actions.confirm}</>
      )}
    </button>
  );
};

export default ButtonUtility;
