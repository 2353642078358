import {
  faArrowDownShortWide,
  faArrowUpShortWide,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import moment from "moment";
import { faMoneyBillTransfer } from "../../../utilities/fontawesomeIcons";
import { useIndexCodCollectionsContext } from "../providers/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const isDisabledLiquidateLabels = (selectedRows) => {
  const { accessTokenPayload } = useAppContext();
  return !selectedRows.every(
    (item) =>
      item.collectionDate &&
      item.ownerId === accessTokenPayload.owner &&
      item.supplierId === selectedRows[0]?.supplierId
  );
};

export const useListProps = () => {
  const { dictionary } = useAppContext();
  const { setModalConfig } = useIndexCodCollectionsContext();

  return {
    path: "/shipment-service/cod-collections",
    sorting: [
      {
        orderBy: "desc",
        column: "collectionDate",
        icon: faArrowDownShortWide,
        label: dictionary.dates.collected_date,
        isDefault: true,
      },
      {
        orderBy: "asc",
        column: "collectionDate",
        icon: faArrowUpShortWide,
        label: dictionary.dates.collected_date,
        isDefault: false,
      },
    ],
    banner: {
      enabled: false,
    },
    title: dictionary.cod_collections.my_cod_collections,
    omnisearch: {
      enabled: true,
      placeholder: dictionary.references.references,
    },
    defaultDate: {
      enabled: true,
      fieldName: "collectionDate",
      label: dictionary.dates.collected_date,
      value: [
        moment().subtract(1, "months").startOf("day").toDate(),
        moment().endOf("day").toDate(),
      ],
    },
    // filters: {
    //   name: "cod_collections",
    //   preserve: false,
    //   list: [
    //     {
    //       fieldName: "collectionDate",
    //       label: dictionary.dates.collection_date,
    //       type: "datepicker",
    //       primary: true,
    //       col: 3,
    //     },
    //   ],
    // },
    massiveActions: [
      {
        onClick: (selectedRows) =>
          setModalConfig({
            isOpen: true,
            title: dictionary.cod_returns.liquidate_labels,
            action: "codLiquidation",
            data: selectedRows.map((r) => r.id),
          }),
        isDisabled: isDisabledLiquidateLabels,
        label: dictionary.cod_returns.liquidate_labels,
        icon: <FontAwesomeIcon icon={faMoneyBillTransfer} />,
        className: "button-1 button-1-transparent",
      },
    ],
  };
};
