import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import {
  callErrorToast,
  callSuccessToast,
} from "../../../utilities/utilities.ts";
import { CodCollectionsService } from "../../../services/shipment/codCollections.ts";
import {
  CodLiquidationPreview,
  convertCodLiquidationPreviewToCreate,
  validate,
} from "../../../Models/CodLiquidationPreview.ts";
import { cleanObject } from "../../../utilities/utilities.ts";
import { useAppContext } from "../../../AppProvider.jsx";

const codCollectionsService = new CodCollectionsService();

interface CodLiquidationPreviewProviderProps {
  children: ReactNode;
  liquidationPreview: CodLiquidationPreview;
  callback?: () => void;
}

interface CodLiquidationPreviewContextValue {
  data: Partial<CodLiquidationPreview>;
  error: ErrorResponse | null; // Error response if any request fails
  loader: boolean;
  edit: (
    liquidation: CodLiquidationPreview,
    property: keyof CodLiquidationPreview | null,
    save?: boolean
  ) => void;
  callback?: () => void;
  onSubmit: () => void;
}

interface ErrorResponse {
  response?: {
    data: Partial<CodLiquidationPreview>;
  };
}

const CodLiquidationPreviewContext = createContext<
  CodLiquidationPreviewContextValue | undefined
>(undefined);

const CodLiquidationPreviewProvider: React.FC<
  CodLiquidationPreviewProviderProps
> = ({ children, liquidationPreview, callback }) => {
  const { dictionary } = useAppContext();

  const [data, setData] =
    useState<Partial<CodLiquidationPreview>>(liquidationPreview);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const prevError = useRef<ErrorResponse>(null);

  const edit = (
    liquidation: CodLiquidationPreview,
    property: keyof CodLiquidationPreview | null
  ): void => {
    liquidation = cleanObject(liquidation);
    if (property) {
      removeError(property);
    }
    setData(liquidation);
  };

  const onSubmit = (): void => {
    const validation = validate(data as CodLiquidationPreview);
    if (!validation.isValid) {
      setError({ response: { data: validation.errors } });
      return;
    }

    setLoader(true);

    const liquidationPreviewUpdated = convertCodLiquidationPreviewToCreate(
      data as CodLiquidationPreview
    );

    codCollectionsService
      .liquidationSubmit(liquidationPreviewUpdated)
      .then((res) => {
        callSuccessToast(dictionary.messages.save_successful);
        if (callback) {
          callback();
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setError(err);
      });
  };

  /**
   * Removes an error related to a specific property.
   * @param property - The property for which the error should be removed.
   */
  const removeError = (property: keyof CodLiquidationPreview) => {
    if (error && error.response) {
      const { data } = error.response;
      if (data && property in data) {
        const { [property]: _, ...newData } = data;
        setError({ response: { data: newData } });
        prevError.current = { response: { data: newData } };
      }
    }
  };

  useEffect(() => {
    if (error) {
      callErrorToast(error);
    }
  }, [error]);

  return (
    <CodLiquidationPreviewContext.Provider
      value={{
        data,
        error,
        loader,
        edit,
        callback,
        onSubmit,
      }}
    >
      {children}
    </CodLiquidationPreviewContext.Provider>
  );
};

const useCodLiquidationPreviewContext =
  (): CodLiquidationPreviewContextValue => {
    const context = useContext(CodLiquidationPreviewContext);
    if (!context) {
      throw new Error(
        "useCodLiquidationPreviewContext must be used within a CodLiquidationPreviewProvider"
      );
    }
    return context;
  };

export { CodLiquidationPreviewProvider, useCodLiquidationPreviewContext };
