import { getDictionary } from "../utilities/utilities";
import { Company } from "./Company";
import { Validate } from "./Validate";

const dictionary = getDictionary();

export interface CodLiquidation {
  codCollections: string[];
  customer: Company;
  expectedAmount: string;
}

export interface CreateLiquidation {
  codCollections: string[];
  customerId: string;
  expectedAmount?: string;
}

export const convertCodLiquidationToCreate = (
  liquidation: CodLiquidation
): CreateLiquidation => {
  return {
    codCollections: liquidation.codCollections,
    customerId: liquidation.customer.id,
    expectedAmount: liquidation.expectedAmount,
  };
};

export const validate = (liquidation: CodLiquidation): Validate => {
  const validate: Validate = { isValid: true, errors: {} };
  if (!liquidation.customer?.id) {
    validate.errors.customer = dictionary.messages.mandatory_field;
    validate.isValid = false;
  }

  return validate;
};
