import React from "react";
import { useAppContext } from "../../AppProvider";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { useIndexCodCollectionsContext } from "./providers/Index";
import { useUpdateCollectionDateContext } from "./providers/UpdateCollectionDate";
import ButtonConfirm from "../../Components/Buttons/Confirm";
import Datepicker from "../../Components/DatePickers/DatePicker/DatePicker";
import { CodCollection } from "../../Models/CodCollection";

const UpdateCollectionDate: React.FC = () => {
  const { dictionary } = useAppContext();
  const { modalConfig } = useIndexCodCollectionsContext();
  const { data, callback, loader, edit, onSubmit } =
    useUpdateCollectionDateContext();

  const handleChange = (
    name: string,
    value: string | number | object | null,
    key: string
  ) => {
    console.log(name, value);
    edit(
      {
        ...data,
        [name]: value,
      } as CodCollection,
      key as keyof CodCollection,
      false
    );
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <h3 className="text-center">{modalConfig.title}</h3>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="label-2">{dictionary.dates.delivery_date} *</label>
          <Datepicker
            name="collectionDate"
            selectedDate={data?.collectionDate || null}
            onChange={(name: string, value: Date | null) =>
              handleChange(name, value, "collectionDate")
            }
          />
        </div>
      </div>
      <div className="d-flex">
        <ButtonUtility
          className="w-50 me-2"
          onClick={() => {
            if (callback) {
              callback();
            }
          }}
        >
          {dictionary.actions.cancel}
        </ButtonUtility>
        <ButtonConfirm
          className="w-50"
          onClick={onSubmit}
          disabled={loader}
          loading={loader}
        >
          {dictionary.actions.save}
        </ButtonConfirm>
      </div>
    </React.Fragment>
  );
};

export default UpdateCollectionDate;
