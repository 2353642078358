import React from "react";
import { useAppContext } from "../../AppProvider";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { useIndexCodCollectionsContext } from "./providers/Index";
import { useCodLiquidationContext } from "./providers/Liquidation";
import ButtonConfirm from "../../Components/Buttons/Confirm";
import { CodLiquidation, validate } from "../../Models/CodLiquidation";
import AsyncSelect from "../../Components/Selects/AsyncSelect/AsyncSelect";
import { getAllCompanies } from "../../Components/Selects/AsyncSelect/api";
import { FetchParam } from "../../Models/FetchParam";
import { getFieldError } from "../../utilities/utilities";
import { Company } from "../../Models/Company";
import { getCompanyOptionStyle } from "../../Components/Selects/AsyncSelect/styles";
import InputBoxIcon from "../../Components/Inputs/InputIcon/InputIcon";
import { faEuro } from "../../utilities/fontawesomeIcons";

const Liquidation: React.FC = () => {
  const { dictionary } = useAppContext();
  const { modalConfig } = useIndexCodCollectionsContext();
  const { data, error, callback, loader, edit, onSubmitPreview } =
    useCodLiquidationContext();

  const handleChange = (
    name: string,
    value: string | number | object | null,
    key: string
  ) => {
    edit(
      {
        ...data,
        [name]: value,
      } as CodLiquidation,
      key as keyof CodLiquidation
    );
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <h3 className="text-center">{modalConfig.title}</h3>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="label-2">{dictionary.registries.customer} *</label>
          <AsyncSelect
            name="customer"
            optionValue="id"
            optionLabel="name"
            value={data.customer ?? null}
            onSearch={(name: string, value: Company | Company[] | null) =>
              handleChange(name, value, "customer")
            }
            loadOptions={(inputValue, pagination) => {
              const params: FetchParam = {
                inputValue,
                pagination,
                sorting: { selector: "name", orderBy: "asc" },
              };
              return getAllCompanies(params);
            }}
            optionStyle={(option: Company) => {
              const CompanyOption = getCompanyOptionStyle(option);
              return <CompanyOption />;
            }}
            isClearable={false}
            error={getFieldError(error, "customer")}
          />
        </div>
        <div className="col-4">
          <label className="label-2">
            {dictionary.dimensions.expected_amount}
          </label>
          <InputBoxIcon
            name={"expectedAmount"}
            type="positive-number"
            onChange={({ target }) =>
              handleChange(target.name, target.value, "expectedAmount")
            }
            value={data.expectedAmount}
            icon={faEuro}
            error={getFieldError(error, "expectedAmount")}
          />
        </div>
      </div>
      <div className="d-flex">
        <ButtonUtility
          className="w-50 me-2"
          onClick={() => {
            if (callback) {
              callback();
            }
          }}
        >
          {dictionary.actions.cancel}
        </ButtonUtility>
        <ButtonConfirm
          className="w-50"
          onClick={onSubmitPreview}
          disabled={loader || !validate(data as CodLiquidation).isValid}
          loading={loader}
        >
          {dictionary.actions.create}
        </ButtonConfirm>
      </div>
    </React.Fragment>
  );
};

export default Liquidation;
